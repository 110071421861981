
$color-primary-dark: #11468a;
$color-primary: #285b9e;
$color-primary-light: #3375cc;
$color-primary-lighter: #c0d0e4;
$color-primary-contrast: #ffffff;

$color-background: #e6ecf0; // blue tintent background
$color-background-light: #ebeff1;
$color-background-lighter: #edf4f6;
$color-background-lightest: #ffffff;
$color-background-contrast: #000000;

$color-background-canvas: #f5f5f5;
$color-active: #1db1c8;
$color-error: #9c0808;

$page-max-width: 2000px