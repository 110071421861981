.roomPage {
    width: 100vw;
    height: 100vh;

    .hidden {
        transition: transform 100ms;
    }

    .hiddenLeft {
        transform: translateX(-200%);
    }

    .hiddenRight {
        transform: translateX(200%);
    }

    .hiddenTop {
        transform: translateY(-200%)
    }
}
  