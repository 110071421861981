@import '../../../styles/variables.scss';

.welcomeModal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.4);
    transition: opacity 0.5s;
    box-shadow: 2px 3px 24px -8px black;
}

.animate {
    opacity: 0;
    pointer-events: none;
}

.container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    overflow: hidden;
    border-radius: 8px;
    background-color: $color-background-lightest;
    color: $color-background-contrast;
    width: 100%;
    max-width: min(90%, 500px);
}

.header {
    background-color: $color-primary;
    color: $color-primary-contrast;
    padding: 20px;
    display: block;
    border-bottom-left-radius: 25px;
}

.title {
    margin: 10px 0;
}

.logo {
    position: absolute;
    right: -20px;
    top: -27px;
    font-size: min(117px, 30vw);
    transform: rotate(309deg)
}

.content {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > *:not(button) {
        width: 100%;
        max-width: 350px;
    }
}
.sectionTitle {
    font-size: 16px;
    font-weight: bold;
    text-decoration: underline;
    margin: 30px 0 15px;
    &:first-child {
        margin-top: 10px;
    }
}

.subtitle {
    padding-left: 25px;
    margin: 0 0 5px;
}

.inputContainer {
    display: flex;
    padding-left: 5px;
    align-items: center;
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }

    input {
        font-size: 14px;
        flex-grow: 1;
        margin-left: 10px;
    }

    button {
        cursor: pointer;
        background-color: transparent;
        color: $color-primary;
        outline: none;
        border: none;
        font-size: 30px;
        padding: 2px 4px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        &:hover {
            background-color:  $color-background-light;
        };
        &:active {
            background-color: $color-background;
        }
        
    }
}

.randomButton {
    &:active {
        i {
            animation: tumble 0.1s infinite;
        }
    }
    @keyframes tumble {
        33% { transform: translateY(3px) rotate(90deg)}
        66% { transform: translateY(-3px) rotate(180deg)}
        100% { transform: translateY(0px) rotate(270deg); }
    }
}

.copyButton {
    transition: transform 50ms;
    &:active {
        transform: scale(1.1);
    }
}


.roomId {
    margin: 0;
    font-size: 25px;
    background-color: $color-background;
    padding: 5px;
    border-radius: 4px;
    font-family: monospace;
    width: max-content;
    margin-left: 10px;
}

.enterButton {
    align-self: end;
    margin-top: 25px;
}
