@import '../../../styles/variables.scss';


$chat-width: 420px;
$chat-max-height: 420px; 

.chatMenu {
    right: 15px;
    bottom: 15px;
    border-radius: 16px;
    height: fit-content;
    width: min($chat-width, calc(100% - 50px));
    max-height: $chat-max-height;
    transition: width 0.5s, max-height 0.5s, transform 0.5s;
    padding: 0;
    overflow: hidden;

    &:not(.show) {
        width: 50px;
        height: 50px;
        max-height: 50px;
        border-radius: 8px;
        padding: 0;
        
        .chatContainer {
            display: none;
        }
        
        .maximizeButton {
            display: block;
        }
    }
}

.unreadCounter {
    position: fixed;
    background-color: red;
    border-radius: 10px;
    width: 15px;
    height: 15px;
    font-size: 7px;
    padding: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-3px,-3px);
    transform-origin: bottom;
    animation: shake 1s ease;
}

@keyframes shake {
    20% { transform: translate(-3px, -10px) rotate(10deg) scale(1.2); }
    30% { transform: translate(-3px, -10px) rotate(-10deg) scale(1.3); }
    40% { transform: translate(-3px, -10px) rotate(10deg) scale(1.3); }
    50% { transform: translate(-3px, -10px) rotate(-10deg) scale(1.2); }
    60% { transform: translate(-3px, -10px) rotate(8deg) scale(1); }
    100% { transform: translate(-3px, -3px) rotate(0deg); }
}

.chatContainer {
    display: flex;
    flex-direction: column;
    font-size: 8px;
    width: 420px;
    position: relative;
}
 
.messageArea {
    border-radius: 8px;
    padding: 10px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: inset 4px 4px 16px -16px black,
                inset -4px -4px 16px -16px rgb(59, 174, 182);
}
.messageContainer {
    display: flex;
    flex-direction: column;
    &:not(:first-child) {
        margin-top: 10px;
    }
}

.username {
    cursor: text;
    font-size: 12px;
    font-weight: bold;
    margin: 0;
}
.message {
    cursor: text;
    padding-left: 3px;
    font-size: 14px;
    margin: 0 0 0 6px;
    word-wrap: break-word;
    background-color: $color-background;
    border-radius: 0 4px 4px 4px;
    padding: 2px 5px;
    max-width: fit-content;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        background-color: inherit;
        left: 0px;
        top: 0px;
        transform: translateX(-100%);
        border-color: $color-background $color-background $color-background-lightest $color-background-lightest;
        border-width: 3px;
        border-style: solid;
        
    }
}

.lastMsgSeparator {
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    color: #413b3b;
}

.inputArea {
    margin-top: 15px;
    display: flex;
}
.input {
    flex-grow: 1;
    margin-right: 10px;
    font-size: 14px;
}

.button {
    cursor: pointer;
    outline: none;
    border: 2px solid $color-background-contrast;
    color: $color-background-contrast;
    border-radius: 8px;
    background-color: transparent;
    font-size: 20px;
    padding: 0 5px;
    display: flex;
    align-items: center;

    &:hover:not(:disabled) {
        color: $color-primary-light;
        border-color: $color-primary-light;
        background-color: $color-background-lighter;
    }
    
    &:active:not(:disabled) {
        color: $color-primary;
        border-color: $color-primary;
        box-shadow: inset 4px 4px 10px -8px  black,
                    inset -4px -4px 10px -16px white;
    }
    &:disabled {
        cursor: default;
        border-color: $color-background;
        &:hover {
            background-color: $color-background-lighter;
        }
    }
}

.minimizeButton {
    width: fit-content;
    transform: scaleX(0.9);
    border-width: 0;
    align-self: end;
}

.maximizeButton {
    display: none;
    width: 100%;
    height: 100%;
    padding: 0;
    border-width: 0
}


.sameUser {
    &.messageContainer {
        margin-top: 2px;
    }
    .username {
        display: none;
    }
    .message {
        &::after {
            display: none;
        }
        border-top-left-radius: 4px;
    }
}

.ownMessage {
    &.messageContainer {
        align-items: end
    }
    .username {
        display: none;
    }
    .message {
        &::after {
            left: initial;
            right: 0;
            transform: scaleX(-1) translateX(-100%);
        }
        border-top-right-radius: 0;
        border-top-left-radius: 4px;
        margin: 0 6px 0 0;

    }
}

.ownMessage.sameUser {
    .message {
        border-top-right-radius: 4px;
    }
}


// scroll bar
.messageArea {
    &::-webkit-scrollbar {
    width: 8px;
    }
    &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
    padding-right: 2px;
    }
      
    &::-webkit-scrollbar-thumb {
    background: rgb(180, 180, 180);
    border-radius: 8px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background: rgb(141, 141, 141);
    }
}