.strokeStyleSelector {
    .linePatterns {
        display: flex;
        justify-content: space-between;
    }
    .widthLabel {
        margin-top: -10em;
        font-size: 14px;
        line-height: 1;
    }
}