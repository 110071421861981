@import '../../../styles/variables.scss';

.colorPallete {
    display: grid;
    margin: 2px;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 6px;
    
    .colorContainer {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: none;
        box-sizing: border-box;
        overflow: hidden;        
        
        &:hover {
            outline: 3px solid $color-primary;
        }
        &.selected {
            outline: 3px solid $color-active;
        }

        &.transparent {
            border: 1px solid black;
            border-radius: 4px;
            .button {
                box-shadow: none;
                &:active {
                    background-color: rgb(226, 226, 226);
                }
            }
            // cross line for transparent option (can't just paint slot transparent)
            &::after {
                content: '';
                pointer-events: none;
                width: 150%;
                height: 2px;
                position: absolute;
                left: 50%;
                top: 50%;
                background-color: red;
                transform-origin: center center;
                transform: translate(-50%, -50%) rotate(-45deg);
            }
            &.selected {
                border: none;
            }
            &:hover {
                border: none;
            }
        }
    }
    

    .button {
        cursor: pointer;
        line-height: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background-color: transparent;
        box-shadow: inset 4px 4px 7px -5px black,
                    inset -2px -2px 7px -5px white;
        &:active {
            box-shadow: none;
        }
    }
    

    .newColor {
        font-size: 16px;
    }
    .colorInput {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
    }

}