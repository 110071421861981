.transparent {
    &::after {
        content: '';
        pointer-events: none;
        width: 65%;
        height: 2px;
        position: absolute;
        left: 50%;
        top: 50%;
        background-color: red;
        transform-origin: center center;
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}