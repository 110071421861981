@import '../../../styles/variables.scss';

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $color-background;
    color: $color-background-contrast;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    opacity: 0;
    transition: opacity 200ms;
}

.content {
    display: flex;
    align-items: baseline;
}
.animate {
    opacity: 1;
}

.text {
    font-size: 30px;
    font-weight: bolder;
    margin: 0;
    margin-right: .25em;
    display: inline;
}

$dot-size: 8px;
$animation-duration: 8s;
.loader {
    display: inline-block;
    position: relative;
    width: $dot-size;
    height: $dot-size;

    span {
        position: absolute;
        left: 0;
        top: 0;
        width: $dot-size;
        height: $dot-size;
        animation: shift $animation-duration infinite;
        span {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: $dot-size;
            height: $dot-size;
            border-radius: $dot-size;
            animation: fall $animation-duration infinite;
            animation-delay: inherit;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: $dot-size;
                height: $dot-size;
                border-radius: $dot-size;
                background-color: currentcolor;
                animation: appear $animation-duration infinite;
                animation-delay: inherit;
                transform: scale(0);
            }
        }
    }
    & > :nth-child(1) {
        animation-delay: 0s;
    }
    & > :nth-child(2) {
        animation-delay: -$animation-duration * 0.25;
    }
    & > :nth-child(3) {
        animation-delay: -$animation-duration * 0.5; 
    }
    & > :nth-child(4) {
        animation-delay: -$animation-duration * 0.75;
    }
}

@keyframes shift {
    25% { transform: translateX(0%); }
    30% { transform: translateX(200%); }
    50% { transform: translateX(200%); }
    55% { transform: translateX(400%); }
  76.5% { transform: translateX(400%); }
    90% { transform: translateX(2000%); }
   100% { transform: translateX(2000%); }
}
@keyframes fall {
    79% { transform: translateY(0%); }
    95% { transform: translateY(4000%); }
   100% { transform: translateY(0%); }  
}
@keyframes appear {
    5% { transform: scale(0); }
    10% { transform: scale(1); }
    85% { transform: scale(1); }
    90% { transform: scale(0); }
    100% { transform: scale(0); }
}

@media (max-width: 600px) {
    @keyframes shift {
        25% { transform: translateX(0%); }
        30% { transform: translateX(200%); }
        50% { transform: translateX(200%); }
        55% { transform: translateX(400%); }
      76.5% { transform: translateX(400%); }
        90% { transform: translateX(1000%); }
       100% { transform: translateX(1000%); }
    }
    @keyframes fall {
        79% { transform: translateY(0%); }
        95% { transform: translateY(3000%); }
       100% { transform: translateY(0%); }  
    }
}