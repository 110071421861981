body {
  margin: 0;
  overflow: hidden;
  font-family: 'Lato', sans-serif;
}

#root {
  width: 100vw;
}

