@import '../../../styles/variables.scss';

.menuContainer {
    cursor: default;
    position: absolute;
    display: flex;
    flex-direction: row;
    background-color: $color-background-lightest;
    color: $color-background-contrast;
    border-radius: 8px;
    font-size: 20px;
    box-shadow: 5px 5px 20px -15px black;
    padding: 0px 10px;

    & > * {
        padding: 10px;
    }
    & .menuContainer > * {
        padding: 0;
    }
}