.animation {
    width: 100%;
    position: relative;
    overflow: hidden;

    .base {
        width: 100%;
        position: static;
        // border: 1px solid red; 
    }

    .container, img:not(.base) {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // border: 1px solid red;
    }
}


// -----------------
// ---Animation 1---
// -----------------
$animation1-duration: 12s;
$animation1-delay: 0s;
.arm1 {
    transform-origin:  5% 31%;
    animation: arm1 $animation1-duration forwards infinite;
}

.forearm1 {
    transform-origin: 11% 43%;
    animation: forearm1 $animation1-duration  forwards infinite;
}

.item1 {
    transform-origin: 16.4% 26.6%;
    animation: item1 $animation1-duration  forwards infinite;
}

@keyframes arm1 {
     5% { transform: rotate(0deg); }
    10% { transform: rotate(-20deg); }
    15% { transform: rotate(-20deg); }
    20% { transform: rotate(0deg); }
    80% { transform: rotate(0deg); }
    85% { transform: rotate(-20deg); }
    90% { transform: rotate(-20deg); }
    95% { transform: rotate(0deg); }
   100% { transform: rotate(0deg); }
}

@keyframes forearm1 {
     5% { transform: rotate(0deg); }
    10% { transform: rotate(40deg); }
    15% { transform: rotate(40deg); }
    20% { transform: rotate(0deg); }
    25% { transform: rotate(-20deg); }
    75% { transform: rotate(-20deg); }
    80% { transform: rotate(0deg); }
    85% { transform: rotate(40deg); }
    90% { transform: rotate(40deg); }
    95% { transform: rotate(-10deg); }
   100% { transform: rotate(0deg); }
}

@keyframes item1 {
    5% { transform: translateX(0%); }
    10% { transform: translateX(5%); }
    90% { transform: translateX(5%); }
    95% { transform: translateX(0%); }
}



 
// -----------------
// ---Animation 2---
// -----------------
$animation2-duration: 10s;
.arm2 {
    transform-origin: 26.9% 74.1%;
    animation: arm2 $animation2-duration  forwards infinite;
}

.forearm2 {
    transform-origin: 30.8% 84.6%;
    animation: forearm2 $animation2-duration forwards infinite;
}

.item2 {  
    transform-origin: 37.5% 75.8%;
    animation: item2 $animation2-duration forwards infinite;
} 

@keyframes arm2 { 
   10% { transform: rotate(-50deg); }
   15% { transform: rotate(-50deg); }
   25% { transform: rotate(0deg); }
   50% { transform: rotate(0deg); }
   60% { transform: rotate(-50deg); }
   65% { transform: rotate(-50deg); }
   75% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}

@keyframes forearm2 { 
   10% { transform: rotate(40deg); }
   15% { transform: rotate(40deg); }
   25% { transform: rotate(0deg); }
   50% { transform: rotate(0deg); }
   60% { transform: rotate(40deg); }
   65% { transform: rotate(40deg); }
   75% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}

@keyframes item2 {
   10% { transform: translate(2.5%, -8.5%); } 
   65% { transform: translate(2.5%, -8.5%); }
   75% { transform: translate(0, 0); }
}

// -----------------
// ---Animation 3---
// -----------------
$animation3-duration: 6s;
$animation3-delay: -8s; 
.arm3 {
    animation: arm3 $animation3-duration infinite;
    transform-origin: 89.5% 41.6%;
}

.item3 {  
    transform-origin: 72.6% 57%;
    animation: item3 $animation3-duration infinite;
}

@keyframes arm3 {
     0% { transform: rotate(-30deg); }
    10% { transform: rotate(-30deg); }
    25% { transform: rotate(0deg); }
    30% { transform: rotate(0deg); }
    45% { transform: rotate(30deg); }
    50% { transform: rotate(30deg); }
    75% { transform: rotate(-30deg); }
   100% { transform: rotate(-30deg); }
}

@keyframes item3 {
     0% { transform: translate(0%, 0%) scale(0);  }
    10% { transform: translate(0%, 0%) scale(0); } 
    16% { transform: translate(0%, 0%)scale(0); }
    19% { transform: translate(0%, 0%) scale(1); }
    30% { transform: translate(0%, 0%)  scale(1); }
    45% { transform: translate(-2%,-10%) scale(1); }
    90% { transform: translate(-2%,-10%) scale(1); }
    95% { transform: translate(-2%,-10%) scale(0); }
   100% { transform: translate(-2%,-10%) scale(0); }
}

